import React from 'react';
import {useSelector} from 'react-redux';
import { selectCollection } from '../../redux/shop/shopSelector';
import {useParams} from 'react-router-dom';
import CollectionItem from '../collection-item/CollectionItem';
import './Collection.scss'
const Collection = () => {
  const params = useParams();
  const collections = useSelector(state => selectCollection(params.collectionid)(state));
  const {title, items} = collections
  
 

  return (
    <div className="collection-page">
      <h2 className="title">{title}</h2>

      <div className="collection-items">
      {items.map(item => {
        
          return  <CollectionItem key={item.id} item={item} />
         
        
        })}
      </div>
  
    </div>
  )
}

export default Collection;