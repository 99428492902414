import React from 'react'
import './FormInput.scss';

const FormInput = ({handleChange,label, ...otherProps}) => {
  return (
    <div className='form-group'>
      <input  onChange={handleChange} className="form-input" {...otherProps} />
      {
        label ? 
        <label  className={`${otherProps.value.length ? 'shrink':''} form-label`}>{label}</label>
        :null
        
      }
    </div>
  )
}

export default FormInput