import React from 'react';
import { Route,Routes} from 'react-router-dom';
import Collection from '../categoryPage/Collection';

import CollectionOverview from '../collection-overview/CollectionOverview';


const Shop = () => {
   
  

  
        return(
            <div className="shop">
                
              
                <Routes>
                <Route index  element={<CollectionOverview />} >
                  
                  </Route>
                  <Route path=":collectionid" element={<Collection />} />
                </Routes>
                
           
              
             
                
               
            </div>
        )
    }


export default Shop;