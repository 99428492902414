import React from 'react';
import MenuItem from '../menu-item/MenuItem';
import {useSelector} from 'react-redux';
import { selectDirectorySections } from '../../redux/directory/directorySelector';

const Directory  = () =>  {
 const directoryItems = useSelector(state => selectDirectorySections(state))
  return (
    <div className="directory-menu">
        {directoryItems.map(section => {
            return <MenuItem key={section.id} title={section.title} imgUrl={section.img} url={section.url} />
        })}
        
    </div>
  ) }


export default Directory