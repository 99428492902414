import {createSlice} from "@reduxjs/toolkit"
const initialState = {
    currentUser:null
}
const userSlice = createSlice({
    name:"user",
    initialState,
    reducers:{
        setCurrentUser:(state,action) => {
            state.currentUser = action.payload
        }
    }
})
// const userReducer = (state={currentUser:null}, action) => {
//     switch (action.type) {
//         case 'SET_CURRENT_USER':
//             return {...state, currentUser:action.payload
//             };
//             default:
//                 return state;
//     }
// }

export default userSlice.reducer;
export const {setCurrentUser} = userSlice.actions