import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.scss';
import { auth } from '../../firebase/firebase.utils';
import { useSelector,useDispatch } from 'react-redux';
import { AiOutlineShopping } from "react-icons/ai";
import CartDropdown from '../cart-dropdown/CartDropdown';
import { selectCartHidden, selectCartItemsCount } from '../../redux/cart/cartSelectors';
import { selectCurrentUser } from '../../redux/user/userSelectors';
import {GiHamburgerMenu} from 'react-icons/gi'
import { toggleCartHidden } from '../../redux/cart/cartReducer';

const Navbar = () => {
    const currentUser = useSelector((state) => selectCurrentUser(state));
    const hidden = useSelector((state) => selectCartHidden(state));
    const wholestate = useSelector(((state)=> state));
    const itemCount = selectCartItemsCount(wholestate)
    const dispatch = useDispatch();
   const [hamToggled,setHamToggled] = React.useState(false);
  return (
    <nav className="navbar">
      
        <Link to='/' className='navbar-brand'>
          
             
              <img src="assets/svgs/nav_logo.svg" alt="navbar logo" />
        </Link>
        
          
            <GiHamburgerMenu className='toggle-button' onClick={()=> setHamToggled(!hamToggled)} />
        
        <div className={`navlist-container ${hamToggled ? 'active':''}`}>
            <ul className={`navlist `}>
                 <li className="nav-item  last">
                    {currentUser ? <button className='sign-btn' onClick={() => auth.signOut()}>Sign Out</button> 
                    : <Link className='signin-link' to='/sign-in'>
                        <button className='sign-btn'>Sign In</button> 
                        </Link>}
                   
                </li>
                <li className="nav-item">
                    <Link className='nav-link ml-5' to='/shop'>Shop</Link>
                </li>
             
                <li className="nav-item">
                    <Link className='nav-link' to='/contact'>Contact</Link>
                </li>
                <li className="nav-item">
                    <span className='nav-link cart-link ' onClick={() => dispatch(toggleCartHidden())}>
                        <AiOutlineShopping className='cart-icon'  />
                        <span className="cart-counter">{itemCount}</span>
                        </span>
                </li>
               
            </ul>
            {
                hidden ? null : <CartDropdown   />
            }
            
        </div>
    </nav>
  )
}

export default Navbar;