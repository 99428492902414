import React from 'react';
import './CollectionItem.scss';
import {AiFillShopping} from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { addCartItem } from '../../redux/cart/cartReducer';


const CollectionItem = ({item}) => {
  const {id,name,imgUrl,price} = item;
  const dispatch = useDispatch();
  return (
    <div key={id} className="collection-item">
        <div  style={{backgroundImage:`url(${imgUrl})`}} className="collection-img">
        
        </div>
        <div className="collection-footer">
            <span className='name'>{name}</span>
            <span className='price'>{`$${price}`}</span>
        </div>
        <button onClick={()=>dispatch(addCartItem(item))} className='add_to_cart_btn'>ADD TO CART<AiFillShopping className='mini_bag_icon' /></button>
    </div>    
  )
}

export default CollectionItem