import React from 'react';
import './CheckoutComponent.scss';
import {useDispatch} from 'react-redux'
import { addCartItem, clearItemFromCart, removeItem } from '../../redux/cart/cartReducer';

const CheckoutComponent = ({item}) => {
    const dispatch = useDispatch();
  return (
    <div className="checkout-item">
        <div className="img-container">
            <img src={item.imgUrl} alt="item"  />
        </div>
        <span className="name">
            {item.name}
            </span>
            <span className="quantity">
                <div onClick={() => dispatch(removeItem(item))} className="arrow">&#10094;</div>
                
                 <span className="value">{item.quantity}</span>  
                <div onClick={() => dispatch(addCartItem(item))} className="arrow">&#10095;</div>
                </span>
            <span className="price">${item.price}</span>
            <div onClick={() => dispatch(clearItemFromCart(item))} className='remove-button'>&#10005;</div>
    </div>
  )
}

export default CheckoutComponent