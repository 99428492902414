import React from "react";
import "./CartItem.scss";

const CartItem = ({ item }) => {
  return (
    <div className="cart-item">
      <img src={item.imgUrl} alt="cart_item" />
      <div className="cart-item-details">
        <span className="name">{item.name}</span>

        <span className="price">
          {item.quantity} x ${item.price}
        </span>
      </div>
    </div>
  );
};

export default CartItem;
