export const shop_data = {
    phones:{id:0,
        title:'phones',
        url:'phones',
        items:[
            {id:31,
                name:'iphone 11 pro max',
                    imgUrl:require('../../bes-store/smartphones/iphone_11.jpg'),
                        price:800},
            {id:32,
                name:'samsung s8 edge /black',
                    imgUrl:require('../../bes-store/smartphones/samsung.jpg'),
                        price:400},
            {id:33,
                name:'samsung a51',
                    imgUrl:require('../../bes-store/smartphones/samsung_a51.jpg'),
                        price:240},
            {id:34,
                name:'xiaomi',
                    imgUrl:require('../../bes-store/smartphones/xaomi.jpg'),
                        price:340},
            {id:35,
                name:'xiaomi 2',
                    imgUrl:require('../../bes-store/smartphones/xaomi2.jpg'),
                        price:300},
        ]},
    pc:{id:1,
    title:'pc',
    url:'pc',
    items:[
        {id:11,
         name:'Dell inspiron 15 7000/ silver',
            imgUrl:require('../../bes-store/pc/dell_silver.jpg'),
            price:  900 },
        {id:12,
         name:'huawei matebook x / black',
            imgUrl:require('../../bes-store/pc/huawei.jpg'),
            price: 1200  },
        {id:13,
         name:'lenove legion / black',
            imgUrl:require('../../bes-store/pc/lenovo_legion.jpg'),
            price:  1300 },
        {id:14,
         name:' apple macbook 1',
            imgUrl:require('../../bes-store/pc/macbook.jpg'),
            price:   2100},
        {id:15,
         name:'apple macbook pro',
            imgUrl:require('../../bes-store/pc/macbook_pro.jpg'),
            price:  2300 },
    ]},
    screens:{id:2,
    title:'screens',
    url:'screens',
    items:[
        {id:21,
            name:'samsung flat screen',
                imgUrl:require('../../bes-store/screens/smart_tv1.jpg'),
                    price:400},
        {id:22,
            name:'toshiba flat screen',
                imgUrl:require('../../bes-store/screens/smart_tv2.jpg'),
                    price:350},
        {id:23,
            name:'scc flat screen',
                imgUrl:require('../../bes-store/screens/smart_tv3.jpg'),
                    price:400},
        {id:24,
            name:'toshiba smart tv',
                imgUrl:require('../../bes-store/screens/smart_tv4.jpg'),
                    price:370},
        {id:25,
            name:'sony smart tv',
                imgUrl:require('../../bes-store/screens/smart_tv5.jpg'),
                    price:740},
    ]},
    cameras:{id:3,
        title:'cameras',
        url:'cameras',
        items:[
            {id:61,
            name:'cannon eos 750',
            imgUrl:require('../../bes-store/cameras/cannon_eos_750.jpg'),
            price:505},
            {id:62,
            name:'cannon mark ii',
            imgUrl:require('../../bes-store/cameras/cannon_mark2.jpg'),
            price:1500},
            {id:63,
            name:'nikon d 750',
            imgUrl:require('../../bes-store/cameras/nikon_d_750.jpg'),
            price:1400},
            {id:64,
            name:'sony rokinon',
            imgUrl:require('../../bes-store/cameras/sony_roknon.jpg'),
            price:3000},
            {id:65,
            name:'zenza bronica',
            imgUrl:require('../../bes-store/cameras/zenza_bronica.jpg'),
            price:600},
        ]},
    headphones:{id:4,
        title:'headphones',
        url:'headphones',
        items:[
            {id:41,
            name:'beats solo 3 wirless',
            imgUrl:require('../../bes-store/headphones/beats.jpg'),
            price:160},
            {id:42,
            name:'apple beats solo 3 wireless',
            imgUrl:require('../../bes-store/headphones/beats2.jpg'),
            price:400},
            {id:43,
            name:'b&o wirelss headphones',
            imgUrl:require('../../bes-store/headphones/bo.jpg'),
            price:300},
            {id:44,
            name:'jbl headphones',
            imgUrl:require('../../bes-store/headphones/jbl.jpg'),
            price:150},
            {id:45,
            name:'sony wirless headphones',
            imgUrl:require('../../bes-store/headphones/sony.jpg'),
            price:180}
        ]},
    watches:{id:5,
    title:'watches',
    url:'watches',
    items:[
        {id:51,
            name:'apple watch ',
                imgUrl:require('../../bes-store/watches/apple_watch1.jpg'),
                    price:120},
        {id:52,
            name:'black led smart watch',
                imgUrl:require('../../bes-store/watches/black_led.jpg'),
                    price:100},
        {id:53,
            name:'black led smart watch 2',
                imgUrl:require('../../bes-store/watches/black_led2.jpg'),
                    price:99},
        {id:54,
            name:'fossil',
                imgUrl:require('../../bes-store/watches/fossil.jpg'),
                    price:300},
        {id:55,
            name:'ancient pocket watch',
                imgUrl:require('../../bes-store/watches/pocket_watch.jpg'),
                    price:3000},
    ]}
}