import React from 'react';
import "./Checkout.scss";
import { selectCartTotal,selectCartItems } from '../../redux/cart/cartSelectors';
import {useSelector} from 'react-redux';
import CheckoutComponent from '../checkout-component/CheckoutComponent';
import StripeButton from '../stripe-button/StripeButton';
const Checkout = () => {
    const total = useSelector(state => selectCartTotal(state))
    const cartItems = useSelector(state => selectCartItems(state))
  return (
    <div className="checkout-page">
        <div className="checkout-header">
            <div className="checkout-block">
                <span>Product</span>
            </div>
            <div className="checkout-block">
                <span>Name</span>
            </div>
            <div className="checkout-block">
                <span>quantity</span>
            </div>
            <div className="checkout-block">
                <span>price</span>
            </div>
            <div className="checkout-block">
                <span>remove</span>
            </div>
        </div>
        <div className="checkout-body">
            {cartItems.length ? cartItems.map(item => {
                return <CheckoutComponent key={item.id} item={item} />
            }): 
            <span className='empty-cart-message'>your cart is empty!!</span>
            }
        </div>
           
        <div className="checkout-total">
            <span>Total: ${total}</span>
            <StripeButton price={total} />
            <div className="test-card-info">
                <h4>Please enter the following test credit card information for payments</h4>
                <span>4242 4242 4242 4242 - EXP: 12/26 - CVC: 666</span>
            </div>
        </div>
    </div>
  )
}

export default Checkout