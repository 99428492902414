import { shop_data } from "./shop_data";
import { createSlice } from "@reduxjs/toolkit"; 
// const INITIAL_STATE = {
//     shopData:shop_data
// }
const initialState = {
    shopData:shop_data
}
const shopSlice = createSlice({
    name:"shop",
    initialState
})
// const shopReducer = (state=INITIAL_STATE,action) => {
//     switch(action.type) {
//         default: return state
//     }
// }

export default shopSlice.reducer;