import React from 'react';
import './ContactPage.scss';
import {ImLocation2} from 'react-icons/im'
import {BsFillTelephoneInboundFill} from 'react-icons/bs'
import {GrMail} from 'react-icons/gr'
const ContactPage = () => {
  return (
    <div className='contact-page'>
      <div className="banner">
        <img src="assets/svgs/banner2.svg" alt="Bermuda logo" />
      </div>
      <div className="contact-info">
        <h2>BERMUDA ELECTRONIC STORE</h2>
        <p>Online shopping store</p>
        <div className="contact-details">
        <span className='location'><ImLocation2 /> Damascus-Syria</span>
        <span className="phone"><BsFillTelephoneInboundFill/>+963 960 608 828</span>
        <span className="mail" ><GrMail/>bes.online.store@gmail.com</span>
        </div>
        
      </div>
    </div>
  )
}

export default ContactPage