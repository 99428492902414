import { initializeApp } from "firebase/app";
import {GoogleAuthProvider,getAuth, signInWithPopup} from 'firebase/auth';
import {getFirestore,doc,getDoc,collection, setDoc} from 'firebase/firestore'


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD8avDI0n5v-SRCqM511kQDYmsl-8L_r84",
  authDomain: "bermuda-electronic-store.firebaseapp.com",
  projectId: "bermuda-electronic-store",
  storageBucket: "bermuda-electronic-store.appspot.com",
  messagingSenderId: "168588719126",
  appId: "1:168588719126:web:e1cb28b46a30e700a1b137",
  measurementId: "G-0VZV9728SX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export const auth = getAuth(app);
export const firestore = getFirestore(app);

export const createUserProfileDocument = async (userAuth, additionalData) => {
    if(!userAuth) return;
    //1- we query for the doc or collection
    const userRef = await doc(firestore,'users',`${userAuth.uid}`);
    const snapShot = await getDoc(userRef);

    if(!snapShot.exists()){
        const {displayName, email} = userAuth;
        const createdAt = new Date();
        try {
            await setDoc(doc(firestore,'users',userAuth.uid), {
                displayName,
                email,
                createdAt,
                ...additionalData
            } )
        } catch (error) {
            console.log('error creating user ', error.message )
        }
    }
return userRef;
}
const provider = new GoogleAuthProvider();
//this give you access to google auth provider class from auth library => it takes custom parameters
provider.setCustomParameters({prompt:"select_account"});
// we want to always trigger google pop up whenever we use this googleAuthProvider for auth and sign in
export const signInWithGoogle = () => signInWithPopup(auth,provider);
//sign in with pop up can take it from many type (google,twitter,facebook) here we defined with google
