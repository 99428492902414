import React from 'react'
import SignIn from '../signin-component/SignIn';
import SignUp from '../sign-up/SignUp';
import { useLocation } from 'react-router-dom';
import Popup from '../popup-component/Popup'
import './SignInPage.scss'
const SignInPage = () => {
  const location = useLocation()
  const [showPopup, setShowPopup] = React.useState(false);
  React.useEffect(() => {
       location.pathname === '/sign-in' ? setShowPopup(true):setShowPopup(false);
       
  },[location.pathname])

  return (

    <div onClick={() => setShowPopup(false)} className='sign-in-sign-up-page'>
      {showPopup ? <Popup  />:null }
  
        <SignIn />
        <SignUp />
    </div>
  )
}

export default SignInPage;