import {createReducer, createSelector} from '@reduxjs/toolkit';


const selectShop = state => state.shop;

export const selectShopData = createSelector(
    [selectShop],
    shop => shop.shopData
)
export const selectCollectionsPreview = createSelector(
    [selectShopData],
    collections => Object.keys(collections).map(key => collections[key])
)

export const selectCollection = collectionId => {
    return createSelector(
        [selectShopData],
        collections => collections[collectionId]
    )
}

