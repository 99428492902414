import React, { Component } from 'react'
import {auth, createUserProfileDocument} from '../../firebase/firebase.utils'
import { createUserWithEmailAndPassword } from 'firebase/auth';
import FormInput from '../re-usable-components/form-input/FormInput';
import './SignUp.scss'

 class SignUp extends Component {
    constructor() {
        super();
        this.state = {
            displayName:"",
            email:"",
            password:"",
            confirmPassword:""
        }
    
    }
    
        handleSubmit = async e => {
            e.preventDefault();
            const {displayName,email,password,confirmPassword} = this.state;
            if(password !== confirmPassword) {
                alert("passwords don't match");
                return;
            }
            
            try {
                const {user} = await createUserWithEmailAndPassword(auth,email,password);
               await createUserProfileDocument(user,{displayName});
               this.setState({
                displayName:"",
                email:"",
                password:"",
                confirmPassword:""
               })
            } catch (error) {
                console.error(error)
            }
        }
    
 
    handleChange = e => {
        const {name,value} = e.target;
        this.setState({[name]:value})
    }
  render() {
    return (
     <div className="sign-up">
        <h2>i Don't have an account</h2>
        <span>Sign up with email and password</span>
        <form onSubmit={this.handleSubmit} className='signup_form' >
            <FormInput onChange={this.handleChange} className="form-input" type="text" name="displayName"  label="display name" value={this.state.displayName}
             required/>
            <FormInput onChange={this.handleChange} className="form-input" type="email" name="email"  label="email" value={this.state.email}
            required />
            <FormInput onChange={this.handleChange} className="form-input" type="password" name="password"  label="password" value={this.state.password}
            required />
            <FormInput onChange={this.handleChange} className="form-input" type="password" name="confirmPassword" label="confirm password" value={this.state.confirmPassword}
            required />
             <button type="submit" className='form-btn2'>Sign Up</button>
        </form>
     </div>
    )
  }
}

export default SignUp