import React, { Component } from 'react'
import FormInput from '../re-usable-components/form-input/FormInput';
import './SignIn.scss';
import { signInWithGoogle,auth } from '../../firebase/firebase.utils';
import { signInWithEmailAndPassword } from 'firebase/auth';
import {AiFillGoogleCircle} from 'react-icons/ai'
export class SignIn extends Component {
    constructor() {
        super();
        this.state = {
            email:'',
            password:''
        }
    }
    handleSubmit = async event =>{
        event.preventDefault();
        const {email,password} = this.state
        try {
          await signInWithEmailAndPassword(auth,email,password)
          this.setState({email:'',password:''});
        } catch (error) {
          console.log(error)
          
        }
        
    }
    handleChange = (event) =>{
        const {value,name} = event.target;
        this.setState({[name]:value});


    }
  render() {
    return (
      <div className="sign_in_container">
        <h2>I Already have an account</h2>
        <span>Sign in with your email and password</span>
        <form onSubmit={this.handleSubmit}  className="signin_form">
           
            <FormInput label="email" handleChange={this.handleChange} type="email" name="email" value={this.state.email} id="email" className='form-input' required />
          
          <FormInput label="password" handleChange={this.handleChange} type="password" name="password" value={this.state.password} id="password" className='form-input' required />
            <div className="btns">
            <button type="submit" className='form-btn'>Sign in</button>
            <button type="button" onClick={signInWithGoogle} className='form-btn-google'>
               <AiFillGoogleCircle className='google-icon'/> Sign in with google</button>
            </div>
            
        </form>

      </div>
    )
  }
}

export default SignIn;