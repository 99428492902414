import React from 'react';
import Directory from '../directory/Directory';
import './Homepage.scss'

const Homepage = () => {
  
  return (
    <div className="homepage">
        <Directory />
     
    </div>
  )
}

export default Homepage