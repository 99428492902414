export const categories = [{
title:'cameras',
img:'assets/bes-store/cameras/cannon_eos_750.jpg',
id:0,
url:'shop/cameras'
},{
title:'headphones',
img:'assets/bes-store/headphones/beats2.jpg',
id:1,
url:'shop/headphones'
},{
title:'screens',
img:'assets/bes-store/screens/smart_tv3.jpg',
id:2,
url:'shop/screens'
},{
title:'laptops',
img:'assets/bes-store/pc/huawei.jpg',
id:3,
url:'shop/pc'
},{
title:'smartphones',
img:'assets/bes-store/smartphones/samsung_a51.jpg',
id:4,
url:'shop/phones'
},{
 title:'watches'   ,
 img:'assets/bes-store/watches/apple_watch1.jpg',
 id:5,
 url:'shop/watches'
}]