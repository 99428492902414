import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from "react-router-dom";
import App from './App';
//redux
 import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import userReducer from './redux/user/userReducer';
import cartReducer from './redux/cart/cartReducer';
import  directoryReducer  from './redux/directory/directoryReducer';
import shopReducer from './redux/shop/shopReducer';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, cartReducer)
 const store = configureStore({reducer:{user:userReducer,
cart:persistedReducer,
directory:directoryReducer,
shop:shopReducer}, middleware: (getDefaultMiddleware) => getDefaultMiddleware({
  serializableCheck: false
})
});
const persistor = persistStore(store)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
    <BrowserRouter>
    <PersistGate loading={null} persistor={persistor}><App /></PersistGate>
    </BrowserRouter>
    </React.StrictMode>
  </Provider>
  
    
 
);


