 import { createSlice } from "@reduxjs/toolkit";
import { addItemToCart, removeItemFromCart } from "./cartUtils";
//  const initial_state = {
//     hidden:true,
//     cartItems:[]
//  }
 const initialState = {
    hidden:true,
    cartItems:[]
 }
 const cartSlice = createSlice({
    name:"cart",
    initialState,
    reducers:{
        toggleCartHidden: (state,action) => {
            state.hidden = !state.hidden
        },
        addCartItem:(state,action) => {
            state.cartItems = addItemToCart(state.cartItems, action.payload)
        },
        clearItemFromCart:(state,action) => {
            state.cartItems = state.cartItems.filter(cartItem => cartItem.id !== action.payload.id)
        },
        removeItem:(state,action) => {
            state.cartItems = removeItemFromCart(state.cartItems, action.payload)
        },
        clearCart:(state,action) => {
            state.cartItems = []
        }
        
    }
 })
//  const cartReducer = (state=initial_state,action) => {
//     switch(action.type) {
//         case "TOGGLE_CART_HIDDEN":
//             return {
//                 ...state,
//                 hidden:!state.hidden
//             } 
//             case "ADD_CART_ITEM": 
//             return{ 
//                 //we spreaded state to keep state propsasthey are then insinde cart items we spreadagain so we keep old items added
//                 ...state,
//                cartItems: addItemToCart(state.cartItems, action.payload)
//             }
//             case "CLEAR_ITEM_FROM_CART":
//                 return {
//                     ...state,
//                     cartItems:state.cartItems.filter(cartItem => cartItem.id !== action.payload.id)
//                 }
//             case "REMOVE_ITEM" : 
//             return {
//                 ...state,
//                 cartItems:removeItemFromCart(state.cartItems, action.payload)
//             }    
//             case"CLEAR_CART":
//             return {...state,cartItems:[]}
//             default: return state;
//     }
// }

export default cartSlice.reducer;
export const {addCartItem, clearItemFromCart,clearCart,toggleCartHidden,removeItem} = cartSlice.actions;