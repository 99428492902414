import React from 'react'
import './Popup.scss';
const Popup = () => {
  return (
    <div className="popup-page">
        <div className="popup-container">
            <h2>NOTE</h2>
            <hr />
            <p>This website uses <span className='firebase'>firebase</span>  authentication <br />
            that it might be blocked in some countries, if you face problems siging in 
             use a VPN</p>
             <button  className='dismiss-btn'>GOT IT</button>
             
        </div>
    </div>
  )
}

export default Popup