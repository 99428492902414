import React from 'react';
import StripeCheckout from 'react-stripe-checkout';

const StripeButton = ({price}) => {
    const priceForStripe = price  * 100;
    const publishableKey = "pk_test_51JrNaMFB3h6HCja6lSnifrLpMMWK2qsKoDM4UqLVktszqxvZ5S0gMhqcUkSZcOKtae1fAEPnvGO0swB11DGprEDo00Rzmoaf1u";
   const onToken = token => {
         console.log(token);
        alert("PAYMENT IS SUCCESSFUL!!!")}
    return (
    <StripeCheckout
    label='Pay Now'
    name='Bermuda Electronic Store'
    shippingAddress
    billingAddress
    description={`Your Total is $${price} USD.`}
    image="assets/svgs/stripe.svg"
    amount={priceForStripe}
    panelLabel="Pay Now"
    token={onToken}
    stripeKey={publishableKey}
    />
  )
}

export default StripeButton