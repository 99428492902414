import { categories } from "../../components/directory/categories";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    categories
}
const directorySlice = createSlice({
    name:"categories",
    initialState
})
// export const directoryReducer = (state=INITIAL_STATE,action) => {
//     switch(action.type) {
//         default:return state
//     }
// }

export default directorySlice.reducer