import React from 'react';
import { useNavigate } from 'react-router-dom';
import './MenuItem.scss'

const MenuItem = ({title,imgUrl,url}) => {
    const navigate = useNavigate();
    
  return (
    <div onClick={()=> navigate(`/${url}`)}  className="menu-item">
        <div style={{
        backgroundImage:`url(${imgUrl})`,
        backgroundSize:'cover',
        backgroundPosition:'center center'
    }} className="background-img" ></div>
    <div className="content">
        <h2 className="title">
            {title}
        </h2>
        <span className='subtitle'>
            SHOP NOW
        </span>
    </div>
</div>
  )
}

export default MenuItem;