import React from 'react';
import "./CollectionOverview.scss";
import CollectionPreview from '../collection-preview/CollectionPreview';
import {useSelector} from 'react-redux';
import { selectCollectionsPreview } from '../../redux/shop/shopSelector';


const CollectionOverview = () => {
    const shopData = useSelector(state => selectCollectionsPreview(state))
  return (
    <div className='collection-overview'>
 {shopData.map((collection) => {
                    return ( 
                        <CollectionPreview key={collection.id} title={collection.title} items={collection.items}    />
                    )
                })}
    </div>
  )
}

export default CollectionOverview