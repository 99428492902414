import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.scss";
import { onSnapshot } from "firebase/firestore";
import { auth, createUserProfileDocument } from "./firebase/firebase.utils";
//components
import Homepage from "./components/homepage/Homepage";
import Shop from "./components/shop/Shop";
import Navbar from "./components/navbar/Navbar";
import SignInPage from "./components/signin-page/SignInPage";
import Checkout from "./components/checkoutPage/Checkout";
import ContactPage from "./components/contact-page/ContactPage";
import NotFoundPage from "./components/notFound-page/NotFoundPage";
import ScrollButton from "./components/scrollbtn/ScrollButton";
//redux

import { useSelector, useDispatch } from "react-redux";
import { selectCurrentUser } from "./redux/user/userSelectors";
import { setCurrentUser } from "./redux/user/userReducer";

const App = () => {
  const currentUser = useSelector((state) => selectCurrentUser(state));
  const dispatch = useDispatch();

  let unsubscribeFromAuth = null;

  React.useEffect(() => {
    unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth);
        onSnapshot(userRef, (snapshot) => {
          dispatch(setCurrentUser({ id: snapshot.id, ...snapshot.data() }))
        });
      } else {
        dispatch(setCurrentUser(null));
      }
    });
    return () => unsubscribeFromAuth();
  }, []);
  //here we create what is called an open subscription between our app and google, it runs and update our state whenever
  // the user state change in google firebase and it keeps listening for changes

  //  componentWillUnmount() {
  //   this.
  //  }

  return (
    <>
      <Navbar />
      <ScrollButton />
      <Routes>
        <Route index element={<Homepage />} />
        <Route path="/shop/*" element={<Shop />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route
          path="/sign-in"
          element={currentUser ? <Navigate to="/" replace /> : <SignInPage />}
        />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default App;
