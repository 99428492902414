import React from 'react';
import './NotFoundPage.scss'

const NotFoundPage = () => {
  return (
    <div className="nf-page">
        <h2>Page Not Found!</h2>
        <span>Please Make Sure You've Entered A Valid URL</span>
    </div>
  )
}

export default NotFoundPage