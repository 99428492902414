import React from 'react'
import './CartDropdown.scss';
import { useSelector } from 'react-redux/es/exports';
import CartItem from '../cart-item/CartItem';
import { selectCartItems } from '../../redux/cart/cartSelectors';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import { clearCart, toggleCartHidden } from '../../redux/cart/cartReducer';
const CartDropdown = () => {
  const cartItems = useSelector(state => selectCartItems(state));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  return (
    <div className="cart-dropdown">
        <div className="cart-items">
        { cartItems.length ?
         cartItems.map(cartItem => {
          return <CartItem key={cartItem.id} item={cartItem} />
        }): <span className="empty-cart-message">Your cart is empty!!</span>
      }
            <button type='button' onClick={() => {navigate('/checkout')
          dispatch(toggleCartHidden())}} className="checkout-btn">GO TO CHEKOUT</button>
          <button type="button" className="clear-cart" onClick={()=> dispatch(clearCart())} > Clear Cart</button>
          <span onClick={()=> dispatch(toggleCartHidden())} className="close-cart">&#10005;</span>
        </div>
    </div>
  )
}

export default CartDropdown